import React, { useState } from "react";
// import { Link } from 'gatsby'

function MoreProdResources() {
	return (
		<section className="container max-w-5xl mx-auto px-6 py-10 md:py-10 md:pb-20">
            <h2 className="section-title">Additional Product Resources</h2>
            <div className="md:w-2/6">
                <div className="rounded-lg shadow-lg flex justify-center items-center p-8">
                    <div className="mr-6">
                        <img src="https://res.cloudinary.com/vantagecircle/image/upload/w_200/v1641463704/gatsbycms/uploads/2022/01/product-update-img.png" alt="Additional Product Resources"/>
                    </div>
                    <div className="vc-colored-btn-1">
                        <a href="https://www.vantagecircle.com/product-updates/">Product Updates</a>
                    </div>
                </div>
            </div>
        </section>
	);
}

export default MoreProdResources
